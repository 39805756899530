import React from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FiShoppingBag } from "react-icons/fi";
import { BsFileEarmark } from "react-icons/bs";
import { RiContactsLine } from "react-icons/ri";

export const links = [
  {
    title: "Dashboard",
    links: [
      {
        name: "dashboard",
        path: "dashboard",
        icon: <FiShoppingBag />,
      },
    ],
  },

  {
    title: "Pages",
    links: [
      // {
      //   name: "Manage orders",
      //   path: "orders",
      //   icon: <AiOutlineShoppingCart />,
      // },
      {
        name: "Manage users",
        path: "users",
        icon: <RiContactsLine />,
      },
      {
        name: "Manage CMS",
        path: "cms",
        icon: <BsFileEarmark />,
      },
    ],
  },
  {
    title: "Catalouge",
    links: [
      {
        name: "Manage category",
        path: "category",
        icon: <RiContactsLine />,
      },
      {
        name: "Manage Product",
        path: "product",
        icon: <AiOutlineShoppingCart />,
      },
    ],
  },
  // {
  //   title: "Apps",
  //   links: [
  //     {
  //       name: "calendar",
  //       icon: <AiOutlineCalendar />,
  //     },
  //     {
  //       name: "kanban",
  //       icon: <BsKanban />,
  //     },
  //     {
  //       name: "editor",
  //       icon: <FiEdit />,
  //     },
  //     {
  //       name: "color-picker",
  //       icon: <BiColorFill />,
  //     },
  //   ],
  // },
  // {
  //   title: "Charts",
  //   links: [
  //     {
  //       name: "line",
  //       icon: <AiOutlineStock />,
  //     },
  //     {
  //       name: "area",
  //       icon: <AiOutlineAreaChart />,
  //     },

  //     {
  //       name: "bar",
  //       icon: <AiOutlineBarChart />,
  //     },
  //     {
  //       name: "pie",
  //       icon: <FiPieChart />,
  //     },
  //     {
  //       name: "financial",
  //       icon: <RiStockLine />,
  //     },
  //     {
  //       name: "color-mapping",
  //       icon: <BsBarChart />,
  //     },
  //     {
  //       name: "pyramid",
  //       icon: <GiLouvrePyramid />,
  //     },
  //     {
  //       name: "stacked",
  //       icon: <AiOutlineBarChart />,
  //     },
  //   ],
  // },
];
