import React from "react";
import { useForm } from "react-hook-form";
import { API } from "../../hooks/API";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { setAdminLoginData } from "../../slice/emailSlice";
import { Button } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
export default function ForgetPassword() {
  const { currentColor } = useStateContext();

  /*Register is used to register the input field in the form , it connect the input field to the useForm hook and enables validation, tracking , and handling of form data changes
  HandleSubmit is used to handle from submission
  formState.errors containes the information of validation Errors */

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  // onSubmit to submit the data in the forgetPassword api
  const onSubmit = async (data) => {
    const header = {
      "Content-Type": "application/json",
    };
    //Call API function to use the API forget password in which we have given the url , header , method and data
    const result = await API("/admin/forgot-password", header, "post", data);

    if (result.status === 200) {
      toast.success(result?.message);
    } else {
      toast.error(result?.message);
    }
    dispatch(setAdminLoginData(data?.email));
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="space-y-4 md:space-y-6"
              action="#"
            >
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your email
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Please Enter Your Email;"
                  // here is use register and its attributes to check the validation
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Please enter a valid email",
                    },
                  })}
                />
                {/* here formState error is used for the email to show the error of validation */}
                {errors.email && (
                  <p
                    style={{ color: "red", fontSize: "12px", marginTop: "5px" }}
                  >
                    {errors.email.message}
                  </p>
                )}
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start"></div>
                {/* Link to go back to login Page  */}
                <a
                  href="login"
                  className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
                >
                  Sign In?
                </a>
              </div>

              <Button
                type="submit"
                color="white"
                bgColor={currentColor}
                text="Submit"
                borderRadius="10px"
                width="full"
              />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
