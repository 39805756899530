import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Editor = ({ data, handleChange }) => {
  return (
    <div>
      <div>
        <CKEditor
          name="description"
          editor={ClassicEditor}
          data={data}
          onChange={(event, editor) => handleChange(event, editor.getData())}
          //   {...register(name)}
        />
      </div>
    </div>
  );
};

export default Editor;
