import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../hooks/API";
import { ImageAPI } from "../hooks/API";
import { toast } from "react-toastify";

const initialState = {
    error: false, // Initialize error state to false
    data: null,
  };

  export const fetchProductData = createAsyncThunk(
    "productData/fetchProductData",
    async () => {
      try {
        const header = {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        };
        const response = await API("/product-data", header, "get");
        return response?.data;
      } catch (error) {
        throw error;
      }
    }
  );

  export const productDataSlice = createSlice({
    name: "productData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        // case for the fetchUserData API calling
        .addCase(fetchProductData.pending, (state) => {
          state.error = false; // Reset error state
        })
        .addCase(fetchProductData.fulfilled, (state, action) => {
          state.data = action.payload;
        })
        .addCase(fetchProductData.rejected, (state, action) => {
          state.error = true;
        })
    },
  });
  
  export default productDataSlice.reducer;
  