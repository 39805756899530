import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../hooks/API";
import { toast } from "react-toastify";

const initialState = {
  error: false, // Initialize error state to false
  data: null,
};

// get Cms Data
export const fetchCMSData = createAsyncThunk(
  "cmsData/fetchCMSData",
  async () => {
    try {
      const header = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      };
      const response = await API("/admin/cms/cms-data", header, "get");
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

// add new Cms data
export const addCms = createAsyncThunk(
  "cmsData/addCms",
  async (cmsData, { rejectWithValue }) => {
    try {
      const header = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      };
      // called imageAPI for store the image with other data
      const response = await API("/admin/cms/add-cms", header, "post", cmsData);

      if (response.status === 200) {
        toast.success(response.message);
        return response?.data;
      } else {
        toast.error(response.message);
        return rejectWithValue(true);
      }
    } catch (error) {
      console.error(error);
      return rejectWithValue(true);
    }
  }
);

// update the data of the existing cms
export const updateCms = createAsyncThunk(
  "cmsData/updateCms",
  async (cmsData, { rejectWithValue }) => {
    try {
      const header = {
        "Content-Type": "application/json",

        Authorization: localStorage.getItem("token"),
      };
      const response = await API(
        "/admin/cms/update-cms",
        header,
        "post",
        cmsData
      );
      if (response.status === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
        return rejectWithValue(true);
      }
    } catch (error) {}
  }
);

//called an API for searching the user
export const searchCms = createAsyncThunk(
  "cmsData/searchCms",
  async (searchValue) => {
    try {
      const header = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      };
      const response = await API("/admin/cms/search-cms", header, "post", {
        searchValue,
      });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

// call Api for deleting the cms

export const deleteCms = createAsyncThunk("userData/deleteCms", async (id) => {
  try {
    const header = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    };
    const response = await API("/admin/cms/delete-cms", header, "post", { id });
    if (response.status === 200) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    return response?.data;
  } catch (error) {
    throw error;
  }
});

export const cmsDataSlice = createSlice({
  name: "cmsData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchCMSData.pending(), (state) => {
        state.error = false; // Reset error state
      })
      .addCase(fetchCMSData.fulfilled(), (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchCMSData.rejected(), (state) => {
        state.error = true;
      })
      // case for the addCms API calling
      .addCase(addCms.pending, (state, action) => {
        state.error = false; // Reset error state
      })
      .addCase(addCms.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(addCms.rejected, (state) => {
        state.error = true;
      })
      // case for the updateCms API calling
      .addCase(updateCms.pending, (state) => {
        state.error = null; // Reset error state
      })
      .addCase(updateCms.fulfilled, (state, action) => {
        // You might want to update state.data here based on your application logic
      })
      .addCase(updateCms.rejected, (state, action) => {
        state.error = action.error.message;
      })

      // case for the DeleteUser API calling
      .addCase(deleteCms.pending, (state) => {
        state.error = null; // Reset error state
      })
      .addCase(deleteCms.fulfilled, (state, action) => {
        state.data = action.payload;
        // You might want to update state.data here based on your application logic
      })
      .addCase(deleteCms.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(searchCms.pending(), (state) => {
        state.error = false; // Reset error state
      })
      .addCase(searchCms.fulfilled(), (state, action) => {
        state.data = action.payload;
      })
      .addCase(searchCms.rejected(), (state) => {
        state.error = true;
      });
  },
});

export default cmsDataSlice.reducer;
