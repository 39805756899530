import { store } from "../store";
import { setLoading } from "../slice/loaderSlice";

export async function API(url, header, method, obj) {
  try {
    store.dispatch(setLoading(true));
    const result = await fetch(`${process.env.REACT_APP_BASE_URL}` + url, {
      method: method,
      headers: header,
      body: obj != null ? JSON.stringify(obj) : obj,
    });
    const responseData = await result.json();
    if (responseData?.status === 403) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    store.dispatch(setLoading(false));
    return responseData;
  } catch (error) {
    console.error("API error catch", error);
  }
}

export async function ImageAPI(url, header, method, obj) {
  try {
    store.dispatch(setLoading(true));
    const result = await fetch(`${process.env.REACT_APP_BASE_URL}` + url, {
      method: method,
      headers: header,
      body: obj,
    });
    const responseData = await result.json();
    if (responseData?.status === 403) {
      //localStorage.removeItem("token");
      // window.location.href = "/login";
    }
    if (responseData) {
      store.dispatch(setLoading(false));
    }
    return responseData;
  } catch (error) {
    console.error("error in the Image APi", error);
  }
}
