import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import ResetPassword from "./pages/Auth/ResetPassword";

const Layout = React.lazy(() => import("./components/Layout"));
const Login = React.lazy(() => import("./pages/Auth/Login"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route exact path="login" name="Login Page" element={<Login />} />
        <Route exact path="forget-password" name="Forget Password Page" element={<ForgetPassword />} />
        <Route exact path="reset-password" name="Reset Password Page" element={<ResetPassword />} />
        <Route path="*" name="Home" element={<Layout />} />
      </Routes>
    </Suspense>
  );
};

export default App;
